<!--
View: Performance Introduction
* @FileDescription: 公司能力   
* @Author: ruixiaozi
* @Email: admin@ruixiaozi.com
* @Date: 2021年05月30日 13:58:05
* @Version: 版本
-->
<template>
  <div class="introduction">
    <div class="title-bar">

    </div>
    <!-- <el-card class="company">
      <h2>渝荣节能科技有限公司简介</h2>
      <div class="company-content">
        <p>渝荣节能科技于2016年成立于西南科技大学国家大学科技园，由中国燃气涡轮研究院退休的老专家程信华研究员牵头成立，主要从事叶轮机设计和节能环保产品的研发与成果转化。</p>
        <p>公司拥有自主开发的叶轮机设计软件。该软件包含轴流压气机、轴流涡轮、离心压气机和向心涡轮等多个模块，在航空和工业领域都得到过大量应用和验证。除航空发动机和弹用涡喷发动机等航空领域外，在涡轮增压器、高炉风机、烟气轮机、TRT、ORC等工业领域也得到了好几十套机组的应用与验证。</p>
        <p>叶轮机设计方面，公司近几年完成了40多个项目的设计，均获一致好评！</p>
      </div>
    </el-card> -->
    <!-- <div class="introduce">
      <div class="title">团队介绍</div>
      <div class="introduce-content">
        <p>公司现有研发人员7人</p>
      </div>
    </div> -->
    <div class="introduce">
      <div class="title">团队介绍</div>
      <div class="introduce-content">
        <p>公司团队在高压比压气机，高膨胀比涡轮，航空发动机以及特殊工质压气机与涡轮方面都具有非常丰富的工程经验，包含气动、强度、结构等多个学科。</p>
        <p>除单一学科的研发服务外，还包含样机研制，特殊用途小功率高速电机直驱叶轮机定制服务以及声源定位与降噪优化。</p>
      </div>
    </div>
    <div class="introduce">
      <div class="title">典型项目</div>
      <div class="projects">
        <el-card>
          某弹用涡喷发动机动气动设计
        </el-card>
        <el-card>
          高压比船用涡轮增压器气动设计
        </el-card>
        <el-card>
          ORC涡轮气动设计
        </el-card>
        <el-card>
          燃料电池空压器气动设计
        </el-card>
        <el-card>
          氦气循环风机气动设计
        </el-card>
        <el-card>
          中央空调离心压气机气动设计
        </el-card>
        <el-card>
          超临界CO<sub>2</sub>机组设计
        </el-card>
        <el-card>
          涡轴发动机动力涡轮气动设计
        </el-card>
        <el-card>
          污水处理曝气风机
        </el-card>
      </div>
    </div>
    
  </div>
</template>

<script>

export default {
  // View name
  name: 'PerformanceIntroduction',
  // View props
  props: {

  },
  // Locally registered components
  components: {

  },
  // View status
  data() {
    return {}
  },
  // Calculate attribute
  computed: {

  },
  // View watch
  watch: {

  },
  // View methods
  methods: {

  },
  // Lifecycle hooks
  // 路由切换，重新创建组件，（在这里获取数据）
  beforeRouteEnter(to, from, next) {

    next();
  },
  // 路由参数变化（或者重复点击当前路由导致参数变化），（在这里获取数据）
  beforeRouteUpdate(to, from, next) {

    next();
  },
};
</script>

<style lang="scss" scoped>
.introduction {
  .title-bar {
    width: 100%;
    height: 150px;
    background-image: url('~@/assets/bg.png');
    background-size: cover;
    background-position: center;

  }

  .company {
    margin: -36px 10% 0 10%;
    text-align: center;
    color: #1E90FF;

    &-content {
      text-align: left;
      p {
        text-indent: 2em;
        font-size: 18px;
        color: #333333;
        line-height: 30px;
        margin: 4px 0;
      }
    }
  }

  .introduce {
    width: 100%;
    display: flex;
    flex-direction: column;
    
    align-items: center;
    color: #1E90FF;
    margin-bottom: 50px;

    .title{
      padding: 30px 0px;
      text-align: center;
      font-size: 24px;
      width: 500px;
      
      &::before,&::after{
        content: "";
        display: inline-block;
        vertical-align: middle;
        width: 10%;
        margin: 0px 10px;
        height: 1px;
        background-color: #1E90FF;
      }
    }

    &-content {
      width: 1224px;
      text-align: left;
      padding: 0 10%;
      p {
        text-indent: 2em;
        font-size: 18px;
        color: #333333;
        line-height: 30px;
        margin: 4px 0;
      }
    }
  }

  .projects {
    max-width: 100%;
    padding: 0 10%;
    box-sizing: border-box;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 16px;
    grid-row-gap: 16px;
    text-align: center;
  }

  
}</style>
